import { FC } from "react";
import { Switch, Route } from "react-router-dom";

import "./App.css";
import Login from "./containers/login";
import Dashboard from "./containers/dashboard";
import NotFoundPage from "./containers/not-found";

const App: FC = () => {
  return (
    <Switch>
      <Route exact={true} path="/login" component={Login} />
      <Route exact={true} path="/*" component={Dashboard} />
      <Route exact={true} path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default App;
