import React from "react";
import { Button, Input, Select, Form } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import "./filter-bar.css";
import { Link } from "react-router-dom";

const FilterBarComponent = ({
  onFiltersSubmit,
  resetFilters
}: {
  onFiltersSubmit: any;
  resetFilters: any;
}) => {
  const [form] = Form.useForm();

  const clearFilters = () => {
    resetFilters();
    form.resetFields();
  };

  const handleFinish = (values: {
    "name-search": string;
    "tag-search": string;
    "province-state-search": string;
  }) => {
    if (
      !values["name-search"] &&
      !values["tag-search"] &&
      !values["province-state-search"]
    ) {
      return;
    }
    onFiltersSubmit(values);
  };

  return (
    <div className="filter-bar-wrapper">
      <Form className="left-side-wrapper" form={form} onFinish={handleFinish}>
        <Form.Item className="name-search" name="name-search">
          <Input placeholder="Search by listing name" />
        </Form.Item>
        <Form.Item className="tag-search" name="tag-search">
          <Select
            placeholder="Filter by tag"
            showSearch
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option value="ACCOMMODATION">Accomodation</Select.Option>
            <Select.Option value="CAMPGROUND">Campground</Select.Option>
            <Select.Option value="RESTAURANT">Restaurant</Select.Option>
            <Select.Option value="CAFE">Cafe</Select.Option>
            <Select.Option value="DRINK">Drink</Select.Option>
            <Select.Option value="HIKING_TRAIL">Hiking Trail</Select.Option>
            <Select.Option value="WALKING_TRAIL">Walking Trail</Select.Option>
            <Select.Option value="DOG_PARK">Dog Park</Select.Option>
            <Select.Option value="BEACH">Beach</Select.Option>
            <Select.Option value="VETERINARY_CLINIC">
              Veterinary Clinic
            </Select.Option>
            <Select.Option value="ACTIVITY">Activity</Select.Option>
            <Select.Option value="GROOMER">Groomer</Select.Option>
            <Select.Option value="PET_STORE">Pet Store</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          className="province-state-search"
          name="province-state-search"
        >
          <Select
            placeholder="Filter by prov / state"
            showSearch
            allowClear
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option value="AB">Alberta</Select.Option>
            <Select.Option value="BC">British Columbia</Select.Option>
            <Select.Option value="MB">Manitoba</Select.Option>
            <Select.Option value="NB">New Brunswick</Select.Option>
            <Select.Option value="NL">Newfoundland and Labrador</Select.Option>
            <Select.Option value="NT">Northwest Territories</Select.Option>
            <Select.Option value="NS">Nova Scotia</Select.Option>
            <Select.Option value="NU">Nunavut</Select.Option>
            <Select.Option value="ON">Ontario</Select.Option>
            <Select.Option value="PE">Prince Edward Island</Select.Option>
            <Select.Option value="QC">Quebec</Select.Option>
            <Select.Option value="SK">Saskatchewan</Select.Option>
            <Select.Option value="YT">Yukon</Select.Option>

            <Select.Option value="Alabama">Alabama</Select.Option>
            <Select.Option value="Alaska">Alaska</Select.Option>
            <Select.Option value="Arizona">Arizona</Select.Option>
            <Select.Option value="Arkansas">Arkansas</Select.Option>
            <Select.Option value="California">California</Select.Option>
            <Select.Option value="Colorado">Colorado</Select.Option>
            <Select.Option value="Connecticut">Connecticut</Select.Option>
            <Select.Option value="Delaware">Delaware</Select.Option>
            <Select.Option value="Florida">Florida</Select.Option>
            <Select.Option value="Georgia">Georgia</Select.Option>
            <Select.Option value="Hawaii">Hawaii</Select.Option>
            <Select.Option value="Idaho">Idaho</Select.Option>
            <Select.Option value="Illinois">Illinois</Select.Option>
            <Select.Option value="Indiana">Indiana</Select.Option>
            <Select.Option value="Iowa">Iowa</Select.Option>
            <Select.Option value="Kansas">Kansas</Select.Option>
            <Select.Option value="Kentucky">Kentucky</Select.Option>
            <Select.Option value="Louisiana">Louisiana</Select.Option>
            <Select.Option value="Maine">Maine</Select.Option>
            <Select.Option value="Maryland">Maryland</Select.Option>
            <Select.Option value="Massachusetts">Massachusetts</Select.Option>
            <Select.Option value="Michigan">Michigan</Select.Option>
            <Select.Option value="Minnesota">Minnesota</Select.Option>
            <Select.Option value="Mississippi">Mississippi</Select.Option>
            <Select.Option value="Missouri">Missouri</Select.Option>
            <Select.Option value="Montana">Montana</Select.Option>
            <Select.Option value="Nebraska">Nebraska</Select.Option>
            <Select.Option value="Nevada">Nevada</Select.Option>
            <Select.Option value="New Hampshire">New Hampshire</Select.Option>
            <Select.Option value="New Jersey">New Jersey</Select.Option>
            <Select.Option value="New Mexico">New Mexico</Select.Option>
            <Select.Option value="New York">New York</Select.Option>
            <Select.Option value="North Carolina">North Carolina</Select.Option>
            <Select.Option value="North Dakota">North Dakota</Select.Option>
            <Select.Option value="Ohio">Ohio</Select.Option>
            <Select.Option value="Oklahoma">Oklahoma</Select.Option>
            <Select.Option value="Oregon">Oregon</Select.Option>
            <Select.Option value="Pennsylvania">Pennsylvania</Select.Option>
            <Select.Option value="Rhode Island">Rhode Island</Select.Option>
            <Select.Option value="South Carolina">South Carolina</Select.Option>
            <Select.Option value="South Dakota">South Dakota</Select.Option>
            <Select.Option value="Tennessee">Tennessee</Select.Option>
            <Select.Option value="Texas">Texas</Select.Option>
            <Select.Option value="Utah">Utah</Select.Option>
            <Select.Option value="Vermont">Vermont</Select.Option>
            <Select.Option value="Virginia">Virginia</Select.Option>
            <Select.Option value="Washington">Washington</Select.Option>
            <Select.Option value="West Virginia">West Virginia</Select.Option>
            <Select.Option value="Wisconsin">Wisconsin</Select.Option>
            <Select.Option value="Wyoming">Wyoming</Select.Option>
          </Select>
        </Form.Item>
        <Button htmlType="submit">Search</Button>
        <Button type="text" onClick={clearFilters}>
          Clear filters
        </Button>
      </Form>
      <Link to="/listings/create">
        <Button type="primary" icon={<PlusOutlined />}>
          Create New
        </Button>
      </Link>
    </div>
  );
};

export default FilterBarComponent;
