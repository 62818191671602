import React, { FC } from "react";
// import axios from "axios";
import {
  Space,
  Tag,
  Table,
  Button,
  Dropdown,
  Menu,
  // Typography,
  Breadcrumb
} from "antd";
import { MoreOutlined, DownOutlined } from "@ant-design/icons";
import { formatDistance, parseISO } from "date-fns";
import { Link } from "react-router-dom";

import FilterBarComponent from "../../components/filter-bar/filter-bar.component";
import OkPickIcon from "../../components/ok-pick-icon/ok-pick-icon.component";
import { Listing } from "../../types/listing";

// const { Text } = Typography;

const Requests: FC = () => {
  const menu = (
    <Menu>
      <Menu.Item key="1">Create Listing</Menu.Item>
      <Menu.Item key="2">Decline Request</Menu.Item>
      <Menu.Item key="3">Mark as Unsure</Menu.Item>
    </Menu>
  );

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: "35%",
      render: (title: string, { ok_pick }: Listing) => {
        if (ok_pick) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <OkPickIcon style={{ marginRight: "16px" }} />
              <span>{title}</span>
            </div>
          );
        }
        return <p>{title}</p>;
      }
    },
    {
      title: "Location",
      dataIndex: "city",
      key: "city",
      width: "20%",
      sorter: true,
      render: (
        city: string,
        { country, province_state }: { country: string; province_state: string }
      ) => {
        const countryIcon = country === "CANADA" ? "🇨🇦" : "🇺🇸";
        return `${countryIcon} ${city}, ${province_state}`;
      }
    },
    {
      title: "Tag",
      dataIndex: "listing_type",
      key: "listing_type",
      sorter: true,
      render: (tag: string) => <Tag>{tag.toLowerCase()}</Tag>
    },
    {
      title: "Submitted",
      dataIndex: "created_at",
      sorter: true,
      key: "created_at",
      render: (date: string) => formatDistance(parseISO(date), new Date())
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      key: "status",
      render: (date: string) => formatDistance(parseISO(date), new Date())
    },
    {
      title: "Action",
      key: "action",
      render: () => (
        <Space size="small">
          <Dropdown overlay={menu} placement={"bottomRight"}>
            <Button style={{ width: "100%" }}>
              <MoreOutlined />
            </Button>
          </Dropdown>
        </Space>
      )
    }
  ];

  const onFiltersSubmit = async (values: {
    "name-search": string;
    "tag-search": string;
    "province-state-search": string;
  }) => {
    // setFilterValues({
    //   name: values["name-search"],
    //   tag: values["tag-search"],
    //   provinceState: values["province-state-search"]
    // });
  };

  const resetFilters = () => {
    // setFilterValues({
    //   name: "",
    //   tag: "",
    //   provinceState: ""
    // });
  };

  return (
    <>
      <FilterBarComponent
        onFiltersSubmit={onFiltersSubmit}
        resetFilters={resetFilters}
      />
      <div className="table-wrapper">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/requests">Requests</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="heading-wrapper">
          <h2>Requests</h2>
          <div>
            <Dropdown overlay={menu}>
              <Button>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
        <div className="results-count-wrapper">
          {/* <Text type="secondary">Showing {totalCount} results...</Text> */}
        </div>
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                // return history.push(`/listings/${record.listing_id}`);
              }
            };
          }}
          columns={columns}
          dataSource={[]}
          size="middle"
          // pagination={{
          //   defaultCurrent: 1,
          //   total: totalCount,
          //   pageSize: 10,
          //   showSizeChanger: false,
          //   onChange: handlePageChange
          // }}
        />
      </div>
    </>
  );
};

export default Requests;
