import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import {
  Space,
  Tag,
  Radio,
  Table,
  Button,
  Dropdown,
  Menu,
  Typography,
  Breadcrumb,
  Spin
} from "antd";
import { MoreOutlined, DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { formatDistance, parseISO } from "date-fns";
import { Link, useHistory } from "react-router-dom";

import FilterBarComponent from "../../components/filter-bar/filter-bar.component";
import OkPickIcon from "../../components/ok-pick-icon/ok-pick-icon.component";
import { Listing } from "../../types/listing";

import "./listings.css";

type MappedListing = Listing & {
  key: string;
};

const { Text } = Typography;

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const listingTypeOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Archived", value: "archived" }
];

const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  }
  // getCheckboxProps: (record: any) => ({
  //   disabled: record.name === "Disabled User", // Column configuration not to be checked
  //   name: record.name
  // })
};

const Listings: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentListings, setCurrentListings] = useState<MappedListing[]>([]);
  const [listingTypeValue, setListingTypeValue] = useState<string>("active");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterValues, setFilterValues] = useState<{
    name: string;
    tag: string;
    provinceState: string;
  }>({ name: "", tag: "", provinceState: "" });

  const history = useHistory();

  const fetchListingsByPagination = async () => {
    const url = `${
      process.env.REACT_APP_API_URI
    }/api/listings/${listingTypeValue}-listings?itemsPerPage=10&page=${currentPage}${
      filterValues.name ? `&name=${filterValues.name}` : ""
    }${filterValues.tag ? `&tag=${filterValues.tag}` : ""}${
      filterValues.provinceState
        ? `&provinceState=${filterValues.provinceState}`
        : ""
    }`;

    try {
      const { data } = await axios({
        method: "get",
        headers: {
          "Content-Type": "application/json"
        },
        url: url,
        withCredentials: true
      });
      const mappedListings: MappedListing[] = data.map((listing: Listing) => ({
        ...listing,
        key: listing.listing_id
      }));

      setCurrentListings(mappedListings);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchListingsAggregateCount = async () => {
    const url = `${
      process.env.REACT_APP_API_URI
    }/api/listings/${listingTypeValue}-listings-count?${
      filterValues.name ? `&name=${filterValues.name}` : ""
    }${filterValues.tag ? `&tag=${filterValues.tag}` : ""}${
      filterValues.provinceState
        ? `&provinceState=${filterValues.provinceState}`
        : ""
    }`;
    try {
      const {
        data: { count }
      } = await axios({
        method: "get",
        headers: {
          "Content-Type": "application/json"
        },
        url: url,
        withCredentials: true
      });

      setTotalCount(count);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = async (page: number) => {
    setLoading(true);
    try {
      setCurrentPage(page);
      await fetchListingsByPagination();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleListingTypeChange = (e: any) => {
    setListingTypeValue(e.target.value);
  };

  useEffect(() => {
    setLoading(true);
    const init = async () => {
      try {
        // get aggregate count
        await Promise.all([
          fetchListingsAggregateCount(),
          fetchListingsByPagination()
        ]);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    init();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchListingsAggregateCount();
    fetchListingsByPagination();
    // eslint-disable-next-line
  }, [listingTypeValue]);

  const onFiltersSubmit = async (values: {
    "name-search": string;
    "tag-search": string;
    "province-state-search": string;
  }) => {
    setFilterValues({
      name: values["name-search"],
      tag: values["tag-search"],
      provinceState: values["province-state-search"]
    });
  };

  const resetFilters = () => {
    setFilterValues({
      name: "",
      tag: "",
      provinceState: ""
    });
  };

  useEffect(() => {
    fetchListingsByPagination();
    fetchListingsAggregateCount();
    // eslint-disable-next-line
  }, [filterValues]);

  const menu = (
    <Menu>
      {listingTypeValue === "active" ? (
        <>
          <Menu.Item key="1">Deactivate Listing</Menu.Item>
          <Menu.Item key="2">Archive Listing</Menu.Item>
        </>
      ) : listingTypeValue === "inactive" ? (
        <>
          <Menu.Item key="1">Activate Listing</Menu.Item>
          <Menu.Item key="2">Archive Listing</Menu.Item>
        </>
      ) : (
        <>
          <Menu.Item key="1">Unarchive Listing</Menu.Item>
        </>
      )}
    </Menu>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: "35%",
      render: (title: string, { ok_pick }: Listing) => {
        if (ok_pick) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <OkPickIcon style={{ marginRight: "16px" }} />
              <span>{title}</span>
            </div>
          );
        }
        return <span>{title}</span>;
      }
    },
    {
      title: "Location",
      dataIndex: "city",
      key: "city",
      width: "20%",
      sorter: true,
      render: (
        city: string,
        { country, province_state }: { country: string; province_state: string }
      ) => {
        const countryIcon = country === "CANADA" ? "🇨🇦" : "🇺🇸";
        return `${countryIcon} ${city}, ${province_state}`;
      }
    },
    {
      title: "Tag",
      dataIndex: "listing_type",
      key: "listing_type",
      sorter: true,
      render: (tag: string) => <Tag>{tag.toLowerCase()}</Tag>
    },
    {
      title: "Created",
      dataIndex: "created_at",
      sorter: true,
      key: "created_at",
      render: (date: string) => formatDistance(parseISO(date), new Date())
    },
    {
      title: "Action",
      key: "action",
      render: () => (
        <Space size="small">
          <Dropdown overlay={menu} placement={"bottomRight"}>
            <Button style={{ width: "100%" }}>
              <MoreOutlined />
            </Button>
          </Dropdown>
        </Space>
      )
    }
  ];

  return (
    <>
      <FilterBarComponent
        onFiltersSubmit={onFiltersSubmit}
        resetFilters={resetFilters}
      />
      <div className="table-wrapper">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/listings">Listings</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="heading-wrapper">
          <h2>Requests</h2>
          <Radio.Group
            options={listingTypeOptions}
            onChange={handleListingTypeChange}
            value={listingTypeValue}
            optionType="button"
            buttonStyle="solid"
          />
          <div>
            <Dropdown overlay={menu}>
              <Button>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
        <div className="results-count-wrapper">
          <Text type="secondary">Showing {totalCount} results...</Text>
        </div>
        {loading ? (
          <div
            style={{
              height: "200px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Spin indicator={loadingIcon} />
          </div>
        ) : (
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  return history.push(`/listings/${record.listing_id}`);
                }
              };
            }}
            columns={columns}
            dataSource={currentListings}
            size="middle"
            pagination={{
              defaultCurrent: 1,
              total: totalCount,
              pageSize: 10,
              showSizeChanger: false,
              onChange: handlePageChange
            }}
          />
        )}
      </div>
    </>
  );
};

export default Listings;
