import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Select,
  Typography,
  Breadcrumb,
  Form,
  Input,
  Switch
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { parseISO } from "date-fns";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import "./single-listing.css";
import axios, { AxiosResponse } from "axios";
import { Listing } from "../../types/listing";
import get from "lodash.get";

const { Title, Text } = Typography;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 }
};

const validateMessages = {
  // eslint-disable-next-line
  required: "${label} is required!",
  types: {
    // eslint-disable-next-line
    email: "${label} is not a valid email!",
    // eslint-disable-next-line
    number: "${label} is not a valid number!"
  },
  number: {
    // eslint-disable-next-line
    range: "${label} must be between ${min} and ${max}"
  }
};

const SingleListing = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [listing, setListing] = useState<Listing | null>(null);
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [isNewListing, setIsNewListing] = useState<boolean>(false);
  const [lng, setLng] = useState<number | null>(null);
  const [lat, setLat] = useState<number | null>(null);

  const [form] = Form.useForm();

  const history = useHistory();

  const listingId =
    (!history.location.pathname.includes("create") &&
      history.location.pathname.split("listings/")[1]) ||
    null;

  const fetchListingById = async () => {
    setLoading(true);
    try {
      const {
        data: [data]
      }: AxiosResponse<Listing[]> = await axios({
        method: "get",
        headers: {
          "Content-Type": "application/json"
        },
        url: `${process.env.REACT_APP_API_URI}/api/listings/get-listing?listingId=${listingId}`,
        withCredentials: true
      });
      setListing(data);
      form.setFieldsValue({
        title: data.title,
        listing_type: data.listing_type,
        listing_status: data.listing_status,
        description: data.description,
        verified: data.verified,
        ok_pick: data.ok_pick,
        dogs_permitted_indoors: data.dogs_permitted_indoors,
        dogs_permitted_on_patio: data.dogs_permitted_on_patio,
        nearby_outdoor_seating: data.nearby_outdoor_seating,
        pickup_window: data.pickup_window,
        address_line_one: data.address_line_one,
        address_line_two: data.address_line_two,
        city: data.city,
        province_state: data.province_state,
        postal_zip: data.postal_zip,
        country: data.country,
        telephone: data.telephone,
        website_link: data.website_link,
        instagram_link: data.instagram_link,
        facebook_link: data.facebook_link,
        internal_note: data.internal_note,
        createdOn: parseISO(data.created_at as any)
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    if (listingId) {
      fetchListingById();
    } else {
      setIsNewListing(true);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const onFinish = async (values: any) => {
    const url = isNewListing
      ? `${process.env.REACT_APP_API_URI}/api/listings/create-listing`
      : `${process.env.REACT_APP_API_URI}/api/listings/update-listing`;
    try {
      const { data }: AxiosResponse<Listing> = await axios({
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        url: url,
        data: {
          ...values,
          listing_id: listing?.listing_id,
          lat: lat ? lat : listing?.lat,
          lng: lng ? lng : listing?.lng
        },
        withCredentials: true
      });
      form.setFieldsValue({
        title: data.title,
        listing_type: data.listing_type,
        listing_status: data.listing_status,
        description: data.description,
        verified: data.verified,
        ok_pick: data.ok_pick,
        dogs_permitted_indoors: data.dogs_permitted_indoors,
        dogs_permitted_on_patio: data.dogs_permitted_on_patio,
        nearby_outdoor_seating: data.nearby_outdoor_seating,
        pickup_window: data.pickup_window,
        address_line_one: data.address_line_one,
        address_line_two: data.address_line_two,
        city: data.city.split(" ").join("_"),
        province_state: data.province_state,
        postal_zip: data.postal_zip,
        country: data.country,
        telephone: data.telephone,
        website_link: data.website_link,
        instagram_link: data.instagram_link,
        facebook_link: data.facebook_link,
        internal_note: data.internal_note,
        createdOn: parseISO(data.created_at as any)
      });
      setInEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAutocompleteSelect = async (values: any) => {
    if (!values) {
      return form.setFieldsValue({
        address_line_one: null,
        city: null,
        province_state: null,
        postal_zip: null,
        country: null
      });
    }
    try {
      const { data } = await axios({
        method: "get",
        url: `https://maps.googleapis.com/maps/api/geocode/json?place_id=${values.value.place_id}&key=${process.env.REACT_APP_GOOGLE_GEOCODING_API_KEY}`
      });

      const { lat, lng } = get(data, [
        "results",
        [0],
        "geometry",
        "location"
      ] as any);

      setLng(lng);
      setLat(lat);

      const { address_components } = get(data, ["results", [0]] as any);

      if (address_components.length === 6) {
        form.setFieldsValue({
          address_line_one: `${address_components[0].long_name} ${address_components[1].long_name}`,
          city: address_components[2].long_name,
          province_state: address_components[3].short_name,
          postal_zip: address_components[5].long_name,
          country: address_components[4].long_name.toUpperCase()
        });
      } else if (address_components.length === 7) {
        form.setFieldsValue({
          address_line_one: `${address_components[0].long_name} ${address_components[1].long_name}`,
          city: address_components[2].long_name,
          province_state: address_components[4].short_name,
          postal_zip: address_components[6].long_name,
          country: address_components[5].long_name.toUpperCase()
        });
      } else {
        form.setFieldsValue({
          address_line_one: `${address_components[0].long_name} ${address_components[1].long_name}`,
          city: address_components[3].long_name,
          province_state: address_components[5].short_name,
          postal_zip: address_components[7].long_name,
          country: address_components[6].long_name.toUpperCase()
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="wrapper">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/listings">Listings</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{listingId}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="heading-wrapper">
        <h2>Create or Edit New Listing</h2>
      </div>
      <div className="content-wrapper">
        <Card style={{ width: "100%" }}>
          {loading ? (
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          ) : (
            <>
              {!inEditMode && !isNewListing && (
                <div className="main-actions-wrapper">
                  <a
                    href={`https://maps.google.com/?q=${listing?.lat},${listing?.lng}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button className="google-map-link">View Map</Button>
                  </a>
                  <Button type="ghost" onClick={() => setInEditMode(true)}>
                    Edit
                  </Button>
                </div>
              )}
              <div className="inner-content">
                <Title level={5}>LISTING</Title>
                <Form
                  {...layout}
                  name="nest-messages"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                  form={form}
                >
                  <Form.Item
                    name="title"
                    label="Listing name"
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="text"
                      disabled={!inEditMode && !isNewListing}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Type of listing"
                    rules={[{ required: true }]}
                    name="listing_type"
                  >
                    <Select disabled={!inEditMode && !isNewListing}>
                      <Select.Option value="ACCOMMODATION">
                        Accomodation
                      </Select.Option>
                      <Select.Option value="CAMPGROUND">
                        Campground
                      </Select.Option>
                      <Select.Option value="RESTAURANT">
                        Restaurant
                      </Select.Option>
                      <Select.Option value="CAFE">Cafe</Select.Option>
                      <Select.Option value="DRINK">Drink</Select.Option>
                      <Select.Option value="HIKING_TRAIL">
                        Hiking Trail
                      </Select.Option>
                      <Select.Option value="WALKING_TRAIL">
                        Walking Trail
                      </Select.Option>
                      <Select.Option value="DOG_PARK">Dog Park</Select.Option>
                      <Select.Option value="BEACH">Beach</Select.Option>
                      <Select.Option value="VETERINARY_CLINIC">
                        Veterinary Clinic
                      </Select.Option>
                      <Select.Option value="ACTIVITY">Activity</Select.Option>
                      <Select.Option value="GROOMER">Groomer</Select.Option>
                      <Select.Option value="PET_STORE">Pet Store</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Listing status"
                    rules={[{ required: true }]}
                    name="listing_status"
                  >
                    <Select disabled={!inEditMode && !isNewListing}>
                      <Select.Option value="ACTIVE">Active</Select.Option>
                      <Select.Option value="INACTIVE">Inactive</Select.Option>
                      <Select.Option value="ARCHIVED">Archived</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="description" label="Description">
                    <Input.TextArea disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item
                    label="Verified"
                    name="verified"
                    valuePropName="checked"
                  >
                    <Switch disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item
                    label="Ok Pick"
                    name="ok_pick"
                    valuePropName="checked"
                  >
                    <Switch disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item
                    label="Dogs permitted indoors"
                    name="dogs_permitted_indoors"
                    valuePropName="checked"
                  >
                    <Switch disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item
                    label="Dogs permitted on patio"
                    name="dogs_permitted_on_patio"
                    valuePropName="checked"
                  >
                    <Switch disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item
                    label="Nearby outdoor seating"
                    name="nearby_outdoor_seating"
                    valuePropName="checked"
                  >
                    <Switch disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item
                    label="Pickup window"
                    name="pickup_window"
                    valuePropName="checked"
                  >
                    <Switch disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Title level={5}>GENERAL</Title>
                  {!inEditMode && isNewListing && (
                    <Form.Item label="Search Address">
                      <Form.Item style={{ marginBottom: "8px" }}>
                        <GooglePlacesAutocomplete
                          apiKey={
                            process.env.REACT_APP_GOOGLE_PLACE_LOOKUP_API_KEY
                          }
                          autocompletionRequest={{
                            componentRestrictions: { country: ["us", "ca"] }
                          }}
                          selectProps={{
                            onChange: handleAutocompleteSelect,
                            placeholder: "Start typing to search a location",
                            inputId: "google-places-autocomplete",
                            isClearable: true
                          }}
                        />
                      </Form.Item>
                      <Text type="secondary">Used to prefill form fields.</Text>
                    </Form.Item>
                  )}

                  <Form.Item
                    name="address_line_one"
                    label="Address 1"
                    rules={[{ required: true }]}
                  >
                    <Input disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item
                    name="address_line_two"
                    label="Address 2"
                    rules={[{ required: false }]}
                  >
                    <Input disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item
                    name="city"
                    label="City"
                    rules={[{ required: true }]}
                  >
                    <Input disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item
                    label="Province / State"
                    rules={[{ required: true }]}
                    name="province_state"
                  >
                    <Select
                      disabled={!inEditMode && !isNewListing}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option!.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Select.Option value="AB">Alberta</Select.Option>
                      <Select.Option value="BC">British Columbia</Select.Option>
                      <Select.Option value="MB">Manitoba</Select.Option>
                      <Select.Option value="NB">New Brunswick</Select.Option>
                      <Select.Option value="NL">
                        Newfoundland and Labrador
                      </Select.Option>
                      <Select.Option value="NT">
                        Northwest Territories
                      </Select.Option>
                      <Select.Option value="NS">Nova Scotia</Select.Option>
                      <Select.Option value="NU">Nunavut</Select.Option>
                      <Select.Option value="ON">Ontario</Select.Option>
                      <Select.Option value="PE">
                        Prince Edward Island
                      </Select.Option>
                      <Select.Option value="QC">Quebec</Select.Option>
                      <Select.Option value="SK">Saskatchewan</Select.Option>
                      <Select.Option value="YT">Yukon</Select.Option>

                      <Select.Option value="Alabama">Alabama</Select.Option>
                      <Select.Option value="Alaska">Alaska</Select.Option>
                      <Select.Option value="Arizona">Arizona</Select.Option>
                      <Select.Option value="Arkansas">Arkansas</Select.Option>
                      <Select.Option value="California">
                        California
                      </Select.Option>
                      <Select.Option value="Colorado">Colorado</Select.Option>
                      <Select.Option value="Connecticut">
                        Connecticut
                      </Select.Option>
                      <Select.Option value="Delaware">Delaware</Select.Option>
                      <Select.Option value="Florida">Florida</Select.Option>
                      <Select.Option value="Georgia">Georgia</Select.Option>
                      <Select.Option value="Hawaii">Hawaii</Select.Option>
                      <Select.Option value="Idaho">Idaho</Select.Option>
                      <Select.Option value="Illinois">Illinois</Select.Option>
                      <Select.Option value="Indiana">Indiana</Select.Option>
                      <Select.Option value="Iowa">Iowa</Select.Option>
                      <Select.Option value="Kansas">Kansas</Select.Option>
                      <Select.Option value="Kentucky">Kentucky</Select.Option>
                      <Select.Option value="Louisiana">Louisiana</Select.Option>
                      <Select.Option value="Maine">Maine</Select.Option>
                      <Select.Option value="Maryland">Maryland</Select.Option>
                      <Select.Option value="Massachusetts">
                        Massachusetts
                      </Select.Option>
                      <Select.Option value="Michigan">Michigan</Select.Option>
                      <Select.Option value="Minnesota">Minnesota</Select.Option>
                      <Select.Option value="Mississippi">
                        Mississippi
                      </Select.Option>
                      <Select.Option value="Missouri">Missouri</Select.Option>
                      <Select.Option value="Montana">Montana</Select.Option>
                      <Select.Option value="Nebraska">Nebraska</Select.Option>
                      <Select.Option value="Nevada">Nevada</Select.Option>
                      <Select.Option value="New Hampshire">
                        New Hampshire
                      </Select.Option>
                      <Select.Option value="New Jersey">
                        New Jersey
                      </Select.Option>
                      <Select.Option value="New Mexico">
                        New Mexico
                      </Select.Option>
                      <Select.Option value="New York">New York</Select.Option>
                      <Select.Option value="North Carolina">
                        North Carolina
                      </Select.Option>
                      <Select.Option value="North Dakota">
                        North Dakota
                      </Select.Option>
                      <Select.Option value="Ohio">Ohio</Select.Option>
                      <Select.Option value="Oklahoma">Oklahoma</Select.Option>
                      <Select.Option value="Oregon">Oregon</Select.Option>
                      <Select.Option value="Pennsylvania">
                        Pennsylvania
                      </Select.Option>
                      <Select.Option value="Rhode Island">
                        Rhode Island
                      </Select.Option>
                      <Select.Option value="South Carolina">
                        South Carolina
                      </Select.Option>
                      <Select.Option value="South Dakota">
                        South Dakota
                      </Select.Option>
                      <Select.Option value="Tennessee">Tennessee</Select.Option>
                      <Select.Option value="Texas">Texas</Select.Option>
                      <Select.Option value="Utah">Utah</Select.Option>
                      <Select.Option value="Vermont">Vermont</Select.Option>
                      <Select.Option value="Virginia">Virginia</Select.Option>
                      <Select.Option value="Washington">
                        Washington
                      </Select.Option>
                      <Select.Option value="West Virginia">
                        West Virginia
                      </Select.Option>
                      <Select.Option value="Wisconsin">Wisconsin</Select.Option>
                      <Select.Option value="Wyoming">Wyoming</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="postal_zip"
                    label="Postal Code / Zip"
                    rules={[{ required: true }]}
                  >
                    <Input disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item
                    name="country"
                    label="Country / Region"
                    rules={[{ required: true }]}
                  >
                    <Select
                      disabled={!inEditMode && !isNewListing}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option!.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Select.Option value="CANADA">Canada</Select.Option>
                      <Select.Option value="UNITED STATES">
                        United States
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="telephone" label="Tel">
                    <Input type="tel" disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Title level={5}>SOCIAL LINKS</Title>
                  <Form.Item name="website_link" label="Website">
                    <Input disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item name="instagram_link" label="Instagram">
                    <Input disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Form.Item name="facebook_link" label="Facebook">
                    <Input disabled={!inEditMode && !isNewListing} />
                  </Form.Item>
                  <Title level={5}>INTERNAL</Title>
                  <Form.Item label="Internal Note">
                    <Form.Item
                      name="internal_note"
                      style={{ marginBottom: "8px" }}
                    >
                      <Input.TextArea disabled={!inEditMode && !isNewListing} />
                    </Form.Item>
                    <Text type="secondary">
                      This note is for internal use only. It will not be
                      displayed on the listing page.
                    </Text>
                  </Form.Item>
                  {!isNewListing && (
                    <Form.Item name="createdOn" label="Created on">
                      <Input disabled />
                    </Form.Item>
                  )}
                  {(inEditMode || isNewListing) && (
                    <div className="buttons-wrapper">
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                      <Button
                        type="text"
                        onClick={async () => {
                          if (isNewListing) {
                            history.push("/listings");
                          } else {
                            fetchListingById();
                            setInEditMode(false);
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </Form>
              </div>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default SingleListing;
