const OkPickIcon = ({ color, style }: { color?: string; style?: any }) => {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <rect width="20" height="20" rx="2" fill="#1B1F22" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.549 13.806V6.817h1.86v3.175l2.18-3.175h2.19l-2.548 3.409 2.77 3.58h-2.346l-2.246-3.136v3.136h-1.86z"
        fill="#fff"
      />
      <path
        d="M9.637 11.166c.46.419.685.646.76.927.147.554-.199.935-.76 1.087-.248.067-.408.11-.867.012 0 0-.324-.166-.93.024-.625.143-.823.453-.823.453-.35.316-.504.356-.751.423-.562.152-1.05-.004-1.198-.558-.074-.28.009-.591.2-1.186 0 0 .348-1.176.756-1.795.294-.447.857-.598.857-.598l.173-.047s.587-.16 1.04.085c.647.352 1.543 1.173 1.543 1.173zM5.416 9.627c.587-.158.869-1.02.63-1.922-.24-.903-.911-1.507-1.498-1.348-.588.159-.87 1.02-.63 1.923s.91 1.506 1.498 1.347zm-.598 1.86c.38-.315.293-1.04-.195-1.622-.488-.581-1.192-.798-1.572-.484-.38.314-.294 1.04.194 1.622.488.58 1.192.797 1.573.483zm3.07-2.528c.587-.159.869-1.02.63-1.923-.24-.903-.911-1.506-1.498-1.347-.588.158-.87 1.02-.63 1.922.24.903.91 1.507 1.498 1.348zm1.927-1.407c-.486-.08-.988.463-1.122 1.213-.135.75.15 1.422.635 1.502.485.08.987-.463 1.122-1.212.134-.75-.15-1.423-.635-1.503z"
        fill="#fff"
      />
    </svg>
  );
};

export default OkPickIcon;
