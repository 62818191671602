import { FC } from "react";
import axios from "axios";
import { Layout, Menu, Avatar, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import Logo from "../logo";

import "./top-navigation.css";

const { Header } = Layout;

const SideNavigation: FC = () => {
  const history = useHistory();
  const handleLogout = async (e: any) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URI}/api/users/signout`,
        withCredentials: true
      });
      history.replace("/");
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Header className="header">
      <div className="top-menu-wrapper">
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["2"]}>
          <Menu.Item key="0" className="logo-key">
            <Link to="/">
              <div className="logo-wrapper">
                <Logo />
              </div>
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="1">nav 1</Menu.Item>
          <Menu.Item key="2">nav 2</Menu.Item>
          <Menu.Item key="3">nav 3</Menu.Item> */}
        </Menu>
        <Dropdown overlay={dropdownMenu}>
          <Avatar className="avatar" icon={<UserOutlined />} />
        </Dropdown>
      </div>
    </Header>
  );
};

export default SideNavigation;
