import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

const WithAuthGuard = (ComposedComponent: any) => {
  const AuthGuard = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<any>(null);

    const history = useHistory();

    useEffect(() => {
      setLoading(true);
      const fetchUser = async () => {
        try {
          const { data } = await axios({
            method: "get",
            headers: {
              "Content-Type": "application/json"
            },
            url: `${process.env.REACT_APP_API_URI}/api/users/currentuser`,
            withCredentials: true
          });
          setUser(data.currentUser);
          setLoading(false);
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
      fetchUser();
    }, []);

    if (loading) {
      return null;
    }

    if (!loading && !user) {
      history.replace("/login");
      return null;
    }

    return <ComposedComponent />;
  };

  return AuthGuard;
};

export default WithAuthGuard;
