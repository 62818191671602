import { FC } from "react";
import { Layout } from "antd";

import { Route, Switch } from "react-router-dom";
import WithAuthGuard from "../../hoc/authentication/auth-guard";

import "./dashboard.css";
import Listings from "../listings";
import { Content } from "antd/lib/layout/layout";
import SideNavigation from "../../components/side-navigation";
import Home from "../home";
import TopNavigation from "../../components/top-navigation";
import SingleListing from "../single-listing";
import Requests from "../requests";
import NotFoundPage from "../not-found";

const Dashboard: FC = () => {
  return (
    <Layout className="layout-wrapper">
      <TopNavigation />
      <Layout>
        <SideNavigation />
        <Layout>
          <Content className="main-content-wrapper">
            <Switch>
              <Route exact={true} path="/" component={Home} />
              <Route exact={true} path="/listings" component={Listings} />
              <Route
                exact={true}
                path="/listings/:id"
                component={SingleListing}
              />
              <Route
                exact={true}
                path="/listings/create"
                component={SingleListing}
              />
              <Route exact={true} path="/requests" component={Requests} />
              <Route exact={true} path="*" component={NotFoundPage} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default WithAuthGuard(Dashboard);
