import "./login.css";
import { useState } from "react";
import axios from "axios";
import { Layout, Typography, Form, Input, Button, Space } from "antd";
import get from "lodash.get";
import { useHistory } from "react-router-dom";

const { Content } = Layout;

const { Title, Text } = Typography;

const Login = () => {
  const [authErrors, setAuthErrors] = useState<any>([]);

  const history = useHistory();

  const onFinish = async (values: any) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URI}/api/users/signin`,
        data: {
          email: values.username,
          password: values.password
        },
        withCredentials: true
      });
      history.replace("/");
    } catch (error) {
      console.log(error);
      get(error, ["response", "data", "errors"]).forEach((error: string[]) => {
        setAuthErrors([error]);
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout className="login-layout">
      <Content>
        <Title level={3}>Login</Title>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            {authErrors && (
              <Space direction="vertical">
                {authErrors.map((error: any, index: number) => (
                  <Text type="danger" key={index}>
                    {error.message}
                  </Text>
                ))}
              </Space>
            )}
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

export default Login;
