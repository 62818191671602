import { FC, useState } from "react";
import { Layout, Menu } from "antd";
import {
  HomeOutlined,
  PushpinOutlined,
  MessageOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import "./side-navigation.css";

const { Sider } = Layout;

const SideNavigation: FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapse = (value: boolean) => {
    setCollapsed(value);
  };
  return (
    <Sider
      width={200}
      className="sider-wrapper"
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <Menu mode="inline" style={{ height: "100%", borderRight: 0 }}>
        <Menu.Item key="1" icon={<HomeOutlined />}>
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<PushpinOutlined />}>
          <Link to="/listings">Listings</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<MessageOutlined />}>
          <Link to="/requests">Requests</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default SideNavigation;
