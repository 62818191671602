import React from "react";

function NotFoundPage({ history }: any) {
  return (
    <div>
      <h1>404 Page Not Found</h1>
    </div>
  );
}

export default NotFoundPage;
